<template>
    <div class="w-full h-auto">
        <div class="grid grid-cols-5 gap-4 lg:gap-0">

            <div ref="venuelist" class="px-6 mt-4 space-y-6 col-span-5 lg:col-span-2">
                <div>
                    <strong>Search Venues</strong>
                    <div class="relative w-full mt-1">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none">
                            <div class="text-black bg-gray-100/50 sm:text-md font-bold bg-none rounded-md py-1 px-2">
                                <SearchIcon class="h-5 w-5" />
                            </div>
                        </div>
                        <input
                            type="text" 
                            class="bg-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-14 p-2.5" 
                            placeholder="Search"
                            v-model="venueSearch"
                        >
                    </div>
                </div>

                <div class="">
                    <div class="grid grid-cols-1 gap-2 justify-center items-center">
                        <div>
                            <strong v-show="!selectedProfile">Select an artist profile</strong>
                            <strong v-show="selectedProfile && !venuesLoaded">Loading...</strong>
                            <strong v-show="selectedProfile && venuesLoaded">{{ venueCount }}</strong>
                        </div>
                        <div class="">
                            <TRichSelect 
                                required 
                                id="profile-selection" 
                                name="profile-selection" class="trichselect"                    
                                :options="artistProfiles"  
                                value-attribute="id" 
                                text-attribute="name" 
                                placeholder="Select artist profile"
                                v-model="selectedProfile"
                            />
                        </div>
                    </div>

                    <div class="grid grid-cols-1 xl:grid-cols-2 gap-x-6 gap-y-6 mt-6 overflow-auto h-[600px]">
                     
                        <div @click="itemClicked(venue)" v-show="filteredVenues.length !== 0" v-for="(venue, index) in filteredVenues" :key="index" class="h-auto bg-white hover:bg-gray-100/10 hover:cursor-pointer border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                            <img class="rounded-t-lg h-44 w-full object-cover" :src="imageCheck(venue.image)" />
                            <div class="px-3 h-2/8 mt-2">
                                <p class="text-md font-bold tracking-tight text-gray-900">{{ venue.name }}</p>
                                <button @click="requestToPlay(venue.id)" :class="cardStyling(venue.requested_to_play)">
                                    {{ requestedToPlay(venue.requested_to_play) }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div ref="googlemap" class="relative col-span-5 lg:col-span-3 z-0 justify-center items-center">
                <GmapMap
                    :center="mapPos"
                    :zoom="mapZoom"
                    map-type-id="terrain"
                    class="w-full h-[800px] lg:h-full"
                >
                    <GmapMarker
                        v-show="venues.length"
                        :key="index"
                        v-for="(venue, index) in venues"
                        :position="venue.address"
                        :clickable="true"
                        :draggable="true"
                        @click="itemClicked(venue)"
                    />
                </GmapMap>

                <div v-show="mapCardVisible" class="ml-2 mt-32 absolute inset-0 h-56 w-52 bg-white border border-gray-200 rounded-lg shadow">
                    <img class="rounded-t-lg h-32 w-full" :src="imageCheck(mapVenue.image)" />
                    <div class="px-3 h-2/8 mt-2">
                        <p href="#" class="text-sm font-bold tracking-tight text-gray-900">{{ mapVenue.name }}</p>
                        <button @click="requestToPlay(mapVenue.id)" :class="cardStyling(mapVenue.requested_to_play)">
                            {{ requestedToPlay(mapVenue.requested_to_play) }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="action-bar justify-between lg:hidden">  
            <div class="gris grid-cols-2 flex justify-center items-center gap-2 bg-gray-100/70 shadow-md w-48 py-2 rounded-3xl">
                <button @click="toggleList()" :class="listStyling">
                    <ViewListIcon class="mr-2 w-5 h-5"/>
                    <span>List</span>
                </button>
                <button @click="toggleMap()" :class="mapStyling">
                    <MapIcon class="mr-2 w-5 h-5"/>
                    <span>Map</span>
                </button>
            </div>
        </div>

        <NotificationSmall ref="notification" />
    </div>
</template>

<script>
import { SearchIcon } from "@vue-hero-icons/outline"
import { TRichSelect } from 'vue-tailwind/dist/components';
import client from './../../../utils/client';
import { apiMethods } from "@/state/helpers";
import NotificationSmall from '../../../components/notifications/small.vue';
import { MapIcon, ViewListIcon } from "@vue-hero-icons/outline"

export default {
    name: "ArtistVenueDirectory",
    data(){
        return {
            artistProfiles: [],
            venues: [],
            selectedProfile: null,
            venueSearch: null,
            venuesLoaded: false,
            mapPos: {
                lat: 53.48095007266323,
                lng: -2.2489512297969174
            },
            mapZoom: 13,
            mapCardVisible: false,
            mapVenue: [],
            isMapView: false,
            isListView: true,
        }
    },
    components: {
        SearchIcon, TRichSelect, NotificationSmall, MapIcon, ViewListIcon
    },
    mounted() {
        this.loadArtists();
    },
    watch: {
        selectedProfile(artist_id) {
            this.loadVenues(artist_id);
        }
    },
    computed: {
        venueCount() {
            return this.selectedProfile && this.filteredVenues.length > 0 ? this.filteredVenues.length + ' venues match your gig location criteria' : 'No venues found with your gig location criteria'
        },
        filteredVenues() {
            return this.venueSearch ? this.venues.filter(f => f.name.toLowerCase().includes(this.venueSearch.toLowerCase())) : this.venues
        },
        listStyling() {
            return this.isListView === true ? 'bg-green-200 hover:bg-green-300/80 cursor-not-allowed px-4 py-1 text-sm rounded-2xl flex font-medium' : 'bg-none hover:bg-gray-200/60 px-4 py-1 text-sm rounded-2xl flex font-medium text-gray-500 cursor-pointer'
        },
        mapStyling() {
            return this.isMapView === true ? 'bg-green-200 hover:bg-green-300/80 cursor-not-allowed px-4 py-1 text-sm rounded-2xl flex font-medium' : 'bg-none hover:bg-gray-200/60 px-4 py-1 text-sm rounded-2xl flex font-medium text-gray-500 cursor-pointer'
        }
    },
    methods: {
        ...apiMethods,
        toggleList(){
            this.isMapView = false;
            this.isListView = true;
            this.$refs.venuelist.scrollIntoView({behavior: 'smooth'});
        },
        toggleMap(){
            this.isMapView = true;
            this.isListView = false;
            this.$refs.googlemap.scrollIntoView({behavior: 'smooth'});
        },
        imageCheck(image) {
            return image && image.url ? image.url : require('@/assets/images/bg_concert.png')
        },
        requestedToPlay(val) {
            return val === 0 ? 'Request' : 'Requested'
        },
        cardStyling(hasRequested) {
                return hasRequested === 1 ? 'my-2 inline-flex items-center px-3 text-sm font-medium py-1 text-center text-gp_pink-default-500 bg-white rounded-lg border border-gp_pink-default-300 focus:ring-4 focus:outline-none focus:ring-blue-300'
                        : 'my-2 inline-flex items-center px-3 text-sm font-medium py-1 text-center text-white bg-gp_pink-default-500 rounded-lg border border-gp_pink-default-300  hover:text-white hover:bg-gp_pink-default-400 focus:ring-4 focus:outline-none focus:ring-blue-300'
        },
        loadArtists(){
            client.get('artist-venue-directory/artists')
                .then((response) => {
                    this.artistProfiles = response.data.data;
                })
                .catch((error) => {
                    this.$refs.notification.createNotification(error.response.data.error.message[0], true);
                })
		},
        loadVenues(artist_id) {
            this.venuesLoaded = false;

            client.get('artist-venue-directory/venues' + '?artist_id=' + artist_id)
                .then((response) => {
                    this.venues = response.data.data;
                    // this.mapPos.lat = this.venues[0].address.lat;
                    // this.mapPos.lng = this.venues[0].address.lng; 
                })
                .catch((error) => {
                    this.$refs.notification.createNotification(error.response.data.error.message[0], true);
                }) 
                .finally(() => {
                    this.venuesLoaded = true;
                })
        },
        requestToPlay(venue_id){
            client.post('artist-venue-directory/request', { artist_id : this.selectedProfile , venue_id : venue_id })
                .then(() => {

                    let index = this.filteredVenues.findIndex((venue) => { return venue.id === venue_id});

                    if (this.filteredVenues[index].requested_to_play === 0){
                        this.filteredVenues[index].requested_to_play = 1;
                    } else {
                        this.filteredVenues[index].requested_to_play = 0;
                    }
                })
                .catch((error) => {
                    this.$refs.notification.createNotification(error.response.data.error.message[0], true);
                })
        },
        itemClicked(venue) {

            if (venue.address.lng !== 0 && venue.address.lat !== 0) {
                this.mapPos = venue.address;
                this.mapZoom = 15;
            }

            this.mapVenue = venue;
            this.mapCardVisible = true;
        }
    }
}

</script>